import React from "react";
import {
  Toolbar,
  Switch,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { FilterList, SwapVert } from "@material-ui/icons";

import IconButtonWithPopper from "../UI/IconButtonWithPopper";

import ListFilter from "./ListFilter";
import ListSorter from "./ListSorter";
import { useUIStore, useUIStoreActions } from "../../hooks/useStore";
import { IContract } from "../../model/IContract";
import { ICustomer } from "../../model/ICustomer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flex: 1,
  },
}));

interface IContractsToolbarProps {
  contracts: IContract[];
  customer: ICustomer;
}
const ContractsToolbar = (props: IContractsToolbarProps) => {
  const { contracts, customer } = props;
  const filterId = "ContractOverviewList-Filter";
  const sorterId = "ContractOverviewList-Sorter";

  const classes = useStyles();
  const ui = useUIStore();
  const uiActions = useUIStoreActions();

  const contractCategories = contracts
    .map((c) => c.category.name)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const sortOptions = ["Typ", "Gesellschaft", "Enddatum", "Kosten"];

  const handleFilterClose = (data: any) => {
    uiActions.setPopperState(filterId, false);

    uiActions.setOverviewSettings(
      ui.overviewSettings.sort,
      ui.overviewSettings.sortMethod,
      data,
      ui.overviewSettings.doIncludeFamily,
      ui.overviewSettings.doShowOnlyActive
    );
  };

  const handleSorterClose = (data: any, isAsc: boolean) => {
    uiActions.setPopperState(sorterId, false);

    uiActions.setOverviewSettings(
      data,
      isAsc ? "asc" : "desc",
      ui.overviewSettings.filter,
      ui.overviewSettings.doIncludeFamily,
      ui.overviewSettings.doShowOnlyActive
    );
  };

  const handleIncludeFamilyToggle = (doIncludeFamily: boolean) => {
    uiActions.setOverviewSettings(
      ui.overviewSettings.sort,
      ui.overviewSettings.sortMethod,
      ui.overviewSettings.filter,
      doIncludeFamily,
      ui.overviewSettings.doShowOnlyActive
    );
  };

  const handleShowActiveToggle = (doShowOnlyActive: boolean) => {
    console.log(doShowOnlyActive);
    uiActions.setOverviewSettings(
      ui.overviewSettings.sort,
      ui.overviewSettings.sortMethod,
      ui.overviewSettings.filter,
      ui.overviewSettings.doIncludeFamily,
      doShowOnlyActive
    );
  };

  const familySwitch = (
    <FormControlLabel
      control={
        <Switch
          checked={ui.overviewSettings.doIncludeFamily}
          onChange={() =>
            handleIncludeFamilyToggle(!ui.overviewSettings.doIncludeFamily)
          }
          value="1"
          color="secondary"
        />
      }
      label="Verträge aus Familie"
    />
  );

  const toolbarContents = (
    <>
      {!!customer && customer.familyMember && customer.familyMember.length
        ? familySwitch
        : null}
      <FormControlLabel
        control={
          <Switch
            checked={!!ui.overviewSettings.doShowOnlyActive}
            onChange={() =>
              handleShowActiveToggle(!ui.overviewSettings.doShowOnlyActive)
            }
            value="1"
            color="secondary"
          />
        }
        label="Nur aktive Verträge"
      />
      <IconButtonWithPopper id={sorterId} icon={<SwapVert />}>
        <ListSorter
          items={sortOptions}
          selected={ui.overviewSettings.sort}
          isAsc={ui.overviewSettings.sortMethod === "asc"}
          onOk={handleSorterClose}
        />
      </IconButtonWithPopper>
      <IconButtonWithPopper
        id={filterId}
        icon={<FilterList />}
        isActive={
          ui.overviewSettings.filter && ui.overviewSettings.filter.length > 0
        }
      >
        <ListFilter
          items={contractCategories}
          selected={ui.overviewSettings.filter}
          onOk={handleFilterClose}
        />
      </IconButtonWithPopper>
    </>
  );

  return (
    <Toolbar>
      <div className={classes.grow} />
      {toolbarContents}
    </Toolbar>
  );
};

export default ContractsToolbar;
