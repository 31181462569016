import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { usePartnerStore, useUIStoreActions } from "../../hooks/useStore";
import { IFile } from "../../model/IFile";
import { messageToPartner } from "../../util/CustomerService";
import FileUploaderControl from "../UI/FileUploaderControl";
import Loading from "../UI/Loading";
import MessageBox from "../UI/MessageBox";
import PhoneLink from "../UI/PhoneLink";

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: theme.spacing(3) },

  cardContent: {},
  cardHeader: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontStyle: "bold",
  },

  map: { width: "100%", height: 300, border: 0 },
  contact: { marginTop: theme.spacing(2) },
}));

interface IMyProfileProps {}

const MyPartner = (props: IMyProfileProps) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<IFile[]>([]);
  const [messageBoxProps, setMessageBoxProps] = useState({
    isOpen: false,
    title: "",
    message: "",
  });

  const partner = usePartnerStore();
  const uiactions = useUIStoreActions();
  const classes = useStyles();

  const informPartner = async () => {
    const res = await messageToPartner(message, files);
    if (res) {
      setMessageBoxProps({
        title: "Sehr gut",
        message: "Ihre Nachricht wurde weitergeleitet...",
        isOpen: true,
      });
      setFiles([]);
      setMessage("");
    } else {
      setMessageBoxProps({
        title: "Fehler",
        message:
          "Ihre Nachricht konnte nicht zugestellt werden, bitte versuchen Sie es später erneut!",
        isOpen: true,
      });
    }
  };

  useEffect(() => {
    uiactions.setIsSearchDisplayed(false);
  }, []);

  if (!partner) return <Loading />;
  return (
    <>
      <Container className={classes.container}>
        <Grid container={true} spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                classes={{
                  title: classes.title,
                }}
                title="Unsere Anschrift"
                className={classes.cardHeader}
              />
              <CardContent className={classes.cardContent}>
                <Grid container={true} spacing={4}>
                  <Grid item md={4} xs={12}>
                    <div>{partner.name}</div>
                    <div>{partner.street}</div>
                    <div>
                      {partner.zip} {partner.city}
                    </div>
                    <div className={classes.contact}>
                      <div>
                        <PhoneLink
                          number={partner.phone}
                          displayWhatsApp={false}
                          displayPhone={true}
                          displayMessage={true}
                          label="Telefon:"
                        />
                      </div>
                      <div>
                        <PhoneLink
                          number={partner.mobile}
                          displayWhatsApp={true}
                          displayPhone={true}
                          displayMessage={true}
                          label="Mobil:"
                        />
                      </div>
                      <div>
                        <PhoneLink
                          number={partner.fax}
                          displayWhatsApp={false}
                          displayPhone={false}
                          displayMessage={true}
                          label="Fax:"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <iframe
                      className={classes.map}
                      title="map"
                      loading="lazy"
                      allowFullScreen
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB4SSL8EBq0J55LmLS7jFW4fH-rDKIc6w0&zoom=16&language=DE&q=${partner.street},${partner.zip} ${partner.city}`}
                    ></iframe>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Schreiben Sie uns"
                className={classes.cardHeader}
                classes={{
                  title: classes.title,
                }}
              />
              <CardContent className={classes.cardContent}>
                <TextField
                  variant="outlined"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  multiline={true}
                  minRows={6}
                  label="Ihre Nachricht"
                  fullWidth={true}
                ></TextField>
                <FileUploaderControl
                  files={files}
                  onAdd={(f: IFile) => {
                    setFiles([...files, f]);
                  }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => informPartner()}
                >
                  Abschicken
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <MessageBox
        isOpen={messageBoxProps.isOpen}
        title={messageBoxProps.title}
        message={messageBoxProps.message}
        onClose={() => {
          setMessageBoxProps({ title: "", message: "", isOpen: false });
        }}
      />
    </>
  );
};

export default MyPartner;
