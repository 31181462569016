import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Paper,
  Chip,
} from "@material-ui/core";
import { MoreVert, Star } from "@material-ui/icons";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  //root: { margin: theme.spacing(2) },
  card: {},
  cardActions: {},
  cardBody: {},
  headerRoot: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontStyle: "bold",
  },
  subheader: {
    color: "white",
    fontSize: 14,
    fontStyle: "italic",
  },
  chip: { margin: theme.spacing(1) },
  content: { fontSize: 14 },
}));

interface IOverviewListEntryProps {
  icon: any;
  title: string;
  subheader: string;
  displayAction: boolean;
  children?: ReactNode;
  className?: string;
  classNameHeader?: string;
  classNameBody?: string;
  actions?: any;
  chip?: any;
}

const OverviewListEntry = (props: IOverviewListEntryProps) => {
  const classes = useStyles();

  const handleContextMenuClickAway = () => {
    //props.handleContextMenuClick(contract);
  };

  const handleContextMenuClick = (el: any) => {
    el.stopPropagation();
    //props.handleContextMenuClick(el, contract);
  };

  let action = null;
  if (props.displayAction) {
    action = (
      <ClickAwayListener onClickAway={handleContextMenuClickAway}>
        <div>
          <IconButton color="inherit" onClick={handleContextMenuClick}>
            {<MoreVert />}
          </IconButton>
        </div>
      </ClickAwayListener>
    );
  }

  let content = null;
  if (props.children) {
    content = (
      <CardContent
        className={
          classes.cardBody +
          (props.classNameBody ? " " + props.classNameBody : "")
        }
      >
        {props.children}
      </CardContent>
    );
  }

  let actions = null;
  if (props.actions) {
    actions = (
      <CardActions className={classes.cardActions}>{props.actions}</CardActions>
    );
  }

  if (props.chip) {
    action = (
      <Chip
        color="secondary"
        size="small"
        icon={props.chip.icon}
        label={props.chip.label}
        className={classes.chip}
      ></Chip>
    );
  }

  return (
    <Paper className={props.className}>
      <Card className={classes.card}>
        <CardHeader
          classes={{
            root: classes.headerRoot,
            title: classes.title,
            subheader: classes.subheader,
          }}
          avatar={props.icon}
          action={action}
          title={props.title}
          subheader={props.subheader}
        />
        <div className={classes.content}>{content}</div>
        {actions}
      </Card>
    </Paper>
  );
};

export default OverviewListEntry;
