import axios from "axios";
import { getToken, logout } from "../util/Auth";

const instance = axios.create({
  //baseURL: ""
  baseURL: process.env.REACT_APP_API_PATH,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (!!token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      logout();
      //window.history.pushState(null, null, "/");
      //TODO display messagebox
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
