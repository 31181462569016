import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Button,
  Hidden,
  Container,
  CssBaseline,
} from "@material-ui/core";

import HeaderAnonymous from "../layout/HeaderAnonymous";

import { forgotPassword } from "../../util/Auth";
import { useNavigate } from "react-router-dom";
import MessageBox from "../UI/MessageBox";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(15),
  },
  content: {
    height: 275,
    overflow: "hidden",
  },
  wallpaper: {
    backgroundImage:
      'linear-gradient(rgba(16, 154, 215, .5), rgba(16, 154, 215, .5)), url("./images/login_background.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btnSubmit: {
    width: "30%",
  },
  btnCancel: {
    width: "30%",
    float: "right",
  },
  btnOk: {
    width: "30%",
    marginTop: theme.spacing(1),
  },
}));

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [isSent, setIsSent] = useState(false);

  const [displayError, setDisplayError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await forgotPassword(user);
      if (!result) setDisplayError(true);
      else setIsSent(true);
    } catch (ex) {
      setDisplayError(true);
    }

    setIsLoading(false);
  };

  const onCancel = async () => {
    navigate("/");
  };

  const done = (
    <Grid item xs={12}>
      <div>Vielen Dank, Sie erhalten in Kürze eine E-Mail.</div>
      <Button
        onClick={() => onCancel()}
        className={classes.btnOk}
        color="primary"
        variant="contained"
        fullWidth
      >
        Ok
      </Button>
    </Grid>
  );

  const form = (
    <>
      <Grid item xs={12}>
        <div>
          Bitte geben Sie Ihre E-Mail Adresse ein.
          <br />
          Sie erhalten anschließend eine E-Mail mit weiteren Instruktionen.
          <br />
          <br />
        </div>
        <TextField
          label="E-Mail Adresse"
          fullWidth
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={() => onSubmit()}
          type="submit"
          className={classes.btnSubmit}
          variant="contained"
          color="primary"
          fullWidth
          disabled={!user || isLoading}
        >
          Abschicken
        </Button>
        <Button
          onClick={() => onCancel()}
          className={classes.btnCancel}
          variant="contained"
          fullWidth
          disabled={isLoading}
        >
          Abbrechen
        </Button>
      </Grid>
    </>
  );

  return (
    <>
      <CssBaseline />
      <HeaderAnonymous />
      <Container fixed className={classes.container}>
        <Grid container spacing={2} className={classes.content}>
          <Hidden smDown>
            <Grid item xs={6} className={`${classes.wallpaper}`}></Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <h4>Passwort vergessen</h4>
            <Grid container item spacing={2}>
              {isSent ? done : form}
            </Grid>
          </Grid>
        </Grid>
        <MessageBox
          isOpen={displayError}
          title="Es ist ein Fehler aufgetreten"
          onClose={() => setDisplayError(false)}
          message="Die eingegebene E-Mail Adresse wurde nicht gefunden"
        ></MessageBox>
      </Container>
    </>
  );
}
