import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Button,
  Hidden,
  Container,
  CssBaseline,
  Link,
} from "@material-ui/core";

import HeaderAnonymous from "../layout/HeaderAnonymous";

import {
  storeToken,
  login,
  setPassword,
  validateToken,
  validateMVPToken,
} from "../../util/Auth";
import { useNavigate } from "react-router-dom";
import MessageBox from "../UI/MessageBox";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(15),
  },
  content: {
    height: 275,
    overflow: "hidden",
  },
  wallpaper: {
    backgroundImage:
      'linear-gradient(rgba(16, 154, 215, .5), rgba(16, 154, 215, .5)), url("./images/login_background.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btnSubmit: {
    width: "30%",
  },
  btnOk: {
    width: "30%",
    marginTop: theme.spacing(1),
  },
  txtPW: {
    marginTop: theme.spacing(1),
  },
}));

export default function SetPasswordPage() {
  const classes = useStyles();
  const [pw_r, setPw_r] = useState("");
  const [pw, setPw] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const [token, setToken] = useState("");
  const [mvpToken, setMvpToken] = useState("");

  const [mode, setMode] = useState("lost");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const t = urlParams.get("token") || "";
    const mt = urlParams.get("mvpToken") || "";
    const m = urlParams.get("mode") || "lost";

    setMode(m);

    const f = async () => {
      let iv = false;
      if (mode === "initial") {
        iv = await validateMVPToken(mt);
        if (iv) setMvpToken(mt);
      } else if (mode === "lost") {
        iv = await validateToken(mt);
        if (iv) setToken(t);
      }
      setIsValid(iv);
    };

    f();
  }, []);

  const onSubmit = async () => {
    if (pw !== pw_r) {
      setErrorText("Die beiden Passwörter stimmen nicht überein");
      setDisplayError(true);
      return;
    }
    if (pw.length < 8) {
      setErrorText(
        "Ihr neues Passwort muss mindestens sechs Zeichen enthalten"
      );
      setDisplayError(true);
      return;
    }

    const hasUpperCase = /[A-Z]/.test(pw) ? 1 : 0;
    const hasLowerCase = /[a-z]/.test(pw) ? 1 : 0;

    const hasNumbers = /\d/.test(pw) ? 1 : 0;

    const hasNonalphas = /\W/.test(pw) ? 1 : 0;

    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
      setErrorText(
        "Ihr neues Passwort muss mindestens drei der folgenden vier Kritieren enthalten:\n - Kleinbuchstaben\n - Großbuchstaben\n - Zahlen\n - Sonderzeichen"
      );
      setDisplayError(true);
      return;
    }

    setIsLoading(true);
    try {
      const result = await setPassword(
        mode,
        mode === "initial" ? mvpToken : token,
        pw,
        pw_r
      );
      setDisplaySuccess(true);
    } catch (ex) {
      setToken("");
      setMvpToken("");
    }

    setIsLoading(false);
  };

  const onBack = async () => {
    navigate("/");
  };

  const Form = (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Neues Passwort"
          type="password"
          fullWidth
          value={pw}
          onChange={(e) => setPw(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Neues Passwort wiederholen"
          fullWidth
          type="password"
          value={pw_r}
          onChange={(e) => setPw_r(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => onSubmit()}
          type="submit"
          className={classes.btnSubmit}
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
        >
          Ändern
        </Button>
      </Grid>
    </Grid>
  );

  const Error = (
    <Grid item xs={12}>
      <div>
        Sie verwenden ein ungültiges Token.
        <br />
        <br />
        Der Link, den Sie vom System bekommen haben, ist lediglich eine
        begrenzte Zeit gültig und nun abgelaufen. Bitte führen Sie die Funktion
        erneut aus.
      </div>
      <Button
        onClick={() => onBack()}
        className={classes.btnOk}
        color="primary"
        variant="contained"
        fullWidth
      >
        Ok
      </Button>
    </Grid>
  );

  const ErrorInitial = (
    <Grid item xs={12}>
      <div>
        Sie verwenden ein ungültiges Token.
        <br />
        <br />
        Der Link, den Sie von Ihrem Makler bekommen haben, ist nur eine
        begrenzte Zeit gültig und nun abgelaufen. Bitte wenden Sie sich an Ihren
        Makler mit der Bitte Ihnen erneut ein Einladungslink zukommen zu lassen.
      </div>
      <Button
        onClick={() => onBack()}
        className={classes.btnOk}
        color="primary"
        variant="contained"
        fullWidth
      >
        Ok
      </Button>
    </Grid>
  );

  const Success = (
    <Grid item xs={12}>
      <div>Vielen Dank, Ihr Passwort wurde erfolgreich geändert.</div>
      <Button
        onClick={() => onBack()}
        className={classes.btnOk}
        color="primary"
        variant="contained"
        fullWidth
      >
        Ok
      </Button>
    </Grid>
  );

  return (
    <>
      <CssBaseline />
      <HeaderAnonymous />
      <Container fixed className={classes.container}>
        <Grid container spacing={2} className={classes.content}>
          <Hidden smDown>
            <Grid item xs={6} className={`${classes.wallpaper}`}></Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <h4>
              {mode === "initial"
                ? "Password festlegen"
                : "Passwort zurücksetzen"}
            </h4>
            {displaySuccess
              ? Success
              : !isValid
              ? mode === "initial"
                ? ErrorInitial
                : Error
              : Form}
          </Grid>
        </Grid>
      </Container>
      <MessageBox
        isOpen={displayError}
        title="Es ist ein Fehler aufgetreten"
        onClose={() => setDisplayError(false)}
        message={errorText}
      ></MessageBox>
    </>
  );
}
