import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@material-ui/core";

import { Search, Cancel, AccountCircle, MoreVert } from "@material-ui/icons";

import { alpha, makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import IconButtonWithPopper from "../UI/IconButtonWithPopper";

//import { setPopperState } from "../../shared/store/ui/actions";
import { logout } from "../../util/Auth";
import { useUIStore, useUIStoreActions } from "../../hooks/useStore";
import ShowLegalDialog from "../dialogs/ShowLegalDialog";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    margin: theme.spacing(1.5),
    marginLeft: 0,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      height: "70",
    },
  },
  logoText: {
    height: 45,
    marginRight: theme.spacing(1.5),
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 400,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.common.white,
  },
  iconButton: {
    color: "inherit",
    padding: theme.spacing(1),
  },
  userButton: {
    marginLeft: theme.spacing(1),
  },
}));

const debouncedSearch = debounce((v, f) => {
  if (!v || v.length >= 3) f(v);
}, 300);

interface IHeaderProps {}
const Header = (props: IHeaderProps) => {
  const classes = useStyles();
  const [currentSearchValue, setCurrentSearchValue] = useState("");
  const [legalMode, setLegalMode] = useState<
    undefined | "INFO" | "COOKIES" | "DATA" | "IMPRINT"
  >();

  const ui = useUIStore();
  const uiActions = useUIStoreActions();
  const navigate = useNavigate();

  const menuIdUser = "Header-UserBtnMenu";
  const menuIdLegal = "Header-LegalBtnMenu";

  useEffect(() => {
    setCurrentSearchValue(ui.searchValue);
  }, [ui.searchValue]);

  const updateSearchValue = (e: any) => {
    const v = e.target.value;

    setCurrentSearchValue(v);
    debouncedSearch(v, uiActions.setSearchValue);
  };

  const clearSearchValue = () => {
    setCurrentSearchValue("");
    uiActions.setSearchValue("");
  };

  const gotoProfile = () => {
    uiActions.setPopperState(menuIdUser, false);
    navigate("/my-profile");
  };

  const changePassword = () => {
    uiActions.setPopperState(menuIdUser, false);
    navigate("/change-password");
  };

  const logoutUser = () => {
    uiActions.setPopperState(menuIdUser, false);
    logout();
    navigate("/login");
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <Link component="button" onClick={() => navigate("/contracts")}>
            <img src="./Logo.png" alt="" className={classes.logo} />
          </Link>
          <Link component="button" onClick={() => navigate("/contracts")}>
            <img
              src="./Text.png"
              alt=""
              className={classes.logoText}
              style={{ display: !ui.isSearchDisplayed ? "block" : undefined }}
            />
          </Link>
          <div className={classes.grow}></div>
          {!!ui.isSearchDisplayed ? (
            <div className={classes.search}>
              <IconButton className={classes.iconButton}>
                <Search />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Suche"
                inputProps={{}}
                value={currentSearchValue}
                onChange={updateSearchValue}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    const target = event.target as HTMLInputElement;
                    target.blur();
                  }
                }}
              />
              {!!currentSearchValue ? (
                <IconButton
                  className={classes.iconButton}
                  onClick={clearSearchValue}
                >
                  <Cancel />
                </IconButton>
              ) : null}
            </div>
          ) : null}

          <div>
            <IconButtonWithPopper
              className={classes.userButton}
              id={menuIdUser}
              icon={<AccountCircle />}
              size="small"
            >
              <List component="nav">
                <ListItem dense button onClick={() => logoutUser()}>
                  <ListItemText primary="Abmelden" />
                </ListItem>
              </List>
            </IconButtonWithPopper>
          </div>
          <div>
            <IconButtonWithPopper
              id={menuIdLegal}
              icon={<MoreVert />}
              size="small"
            >
              <List component="nav">
                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("DATA");
                  }}
                >
                  <ListItemText primary="Datenschutz" />
                </ListItem>

                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("INFO");
                  }}
                >
                  <ListItemText primary="Informationspflichten" />
                </ListItem>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("IMPRINT");
                  }}
                >
                  <ListItemText primary="Impressum" />
                </ListItem>
              </List>
            </IconButtonWithPopper>
          </div>
        </Toolbar>
      </AppBar>
      <ShowLegalDialog
        mode={legalMode}
        onClose={() => {
          setLegalMode(undefined);
        }}
      ></ShowLegalDialog>
    </div>
  );
};

export default Header;
