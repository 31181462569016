import axios from "axios";

console.log(process.env.REACT_APP_API_PATH);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  //baseURL: "/auth"
});

export default instance;
