const ContractState = {
  aktiv: { label: "aktiv", isActive: true },
  beitragsfrei: { label: "beitragsfrei", isActive: true },
  Bestandsübertragung: { label: "Bestandsübertragung", isActive: true },
  fremd: { label: "fremd", isActive: true },
  "in Arbeit": { label: "in Arbeit ", isActive: true },
  Kombivertrag: { label: "Kombivertrag", isActive: true },
  Leistungsphase: { label: "Leistungsphase", isActive: true },
  NEU: { label: "NEU", isActive: true },
  stornogefährdet: { label: "stornogefährdet", isActive: true },
  versandt: { label: "versandt", isActive: true },
  "VN-Wechsel": { label: "VN-Wechsel", isActive: true },

  abgelaufen: { label: "abgelaufen", isActive: false },
  Angebot: { label: "Angebot", isActive: false },
  "Angebot angenommen": { label: "Angebot angenommen", isActive: false },
  "Angebot abgelehnt": { label: "Angebot abgelehnt", isActive: false },
  inaktiv: { label: "inaktiv", isActive: false },
  Interessent: { label: "Interessent", isActive: false },
  Kontakt: { label: "Kontakt", isActive: false },
  ruhend: { label: "ruhend", isActive: false },
  storno: { label: "storno ", isActive: false }
};
Object.freeze(ContractState);
export default ContractState;
