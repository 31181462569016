import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
  useCustomerDocumentsStore,
  useCustomerStore,
  useUIStoreActions,
} from "../../hooks/useStore";
import { asDisplayDate } from "../../util/Utilities";
import ContactPartnerDialog from "../dialogs/ContactPartnerDialog";
import DocumentOverview from "../UI/DocumentOverview";
import ValueWithLabel from "../UI/ValueWithLabel";

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: theme.spacing(3) },
  cardContent: {},
  cardHeader: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },

  title: {
    fontSize: 18,
    fontStyle: "bold",
  },
}));

interface IMyProfileProps {}

const MyProfile = (props: IMyProfileProps) => {
  const customer = useCustomerStore();
  const documents = useCustomerDocumentsStore();
  const classes = useStyles();

  const uiactions = useUIStoreActions();

  useEffect(() => {
    uiactions.setIsSearchDisplayed(false);
  }, []);

  return (
    <Container className={classes.container} maxWidth="md">
      <Card>
        <CardHeader
          classes={{
            title: classes.title,
          }}
          title="Meine Daten"
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <ValueWithLabel
            label="Anschrift"
            value={
              customer?.companyName || customer?.firstName || customer?.lastName
            }
          >
            <div>{customer?.companyName}</div>
            <div>
              {customer?.salutation} {customer?.firstName} {customer?.lastName}
            </div>
            <div>{customer?.street} </div>
            <div>
              {customer?.zip} {customer?.city}
            </div>
          </ValueWithLabel>

          <ValueWithLabel
            label="Geburtstag"
            value={asDisplayDate(customer?.birthday)}
          />
          <ValueWithLabel label="Familienstand" value={customer?.familyState} />
          <ValueWithLabel label="Nationalität" value={customer?.nationality} />

          <ValueWithLabel
            label="E-Mail"
            value={customer?.mail01 || customer?.mail02}
          >
            <div>{customer?.mail01}</div>
            {customer?.mail02 ? (
              <div>{customer?.mail02} (alternativ)</div>
            ) : null}
          </ValueWithLabel>

          <ValueWithLabel
            label="Telefon"
            value={
              customer?.phoneMobile ||
              customer?.phonePrivate ||
              customer?.phoneBusiness ||
              customer?.fax
            }
          >
            {customer?.phoneMobile ? (
              <div>Mobil: {customer?.phoneMobile}</div>
            ) : null}
            {customer?.phonePrivate ? (
              <div>Privat: {customer?.phonePrivate}</div>
            ) : null}
            {customer?.phoneBusiness ? (
              <div>Geschäftlich: {customer?.phoneBusiness}</div>
            ) : null}
            {customer?.fax ? <div>Fax: {customer?.fax}</div> : null}
          </ValueWithLabel>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Meine Dokumente"
          className={classes.cardHeader}
          classes={{
            title: classes.title,
          }}
        />
        <CardContent className={classes.cardContent}>
          <DocumentOverview documents={documents} />
        </CardContent>
      </Card>
      <ContactPartnerDialog />
    </Container>
  );
};

export default MyProfile;
