import {
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
} from "@material-ui/core";
import { useCustomerDocumentsStore } from "../../hooks/useStore";
import DocumentOverview from "../UI/DocumentOverview";

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: theme.spacing(3) },
  cardContent: {},
  cardHeader: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },

  title: {
    fontSize: 18,
    fontStyle: "bold",
  },
}));

interface IMyDocumentsProps {}

const MyDocuments = (props: IMyDocumentsProps) => {
  const classes = useStyles();
  const documents = useCustomerDocumentsStore();

  return (
    <Container className={classes.container} maxWidth="md">
      <Card>
        <CardHeader
          title="Meine Dokumente"
          className={classes.cardHeader}
          classes={{
            title: classes.title,
          }}
        />
        <CardContent className={classes.cardContent}>
          <DocumentOverview documents={documents} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default MyDocuments;
