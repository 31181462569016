import React, { useContext } from "react";
import { StoreContext } from "../contexts/StoreContext";
import { IContractStoreActions } from "../model/IContractStoreActions";
import { IUIState } from "../model/IUIState";
import { IUIStateActions } from "../model/IUIStateActions";

const useUIStore = (): IUIState => {
  const store = useContext(StoreContext);
  return store.ui;
};

const useUIStoreActions = (): IUIStateActions => {
  const store = useContext(StoreContext);
  return {
    setIsLoading: store.setIsLoading,
    setContractFilter: store.setContractFilter,
    setIsSearchDisplayed: store.setIsSearchDisplayed,
    setOverviewSettings: store.setOverviewSettings,
    setPopperState: store.setPopperState,
    setSearchValue: store.setSearchValue,
  };
};

const useCustomerStore = () => {
  const store = useContext(StoreContext);
  return store.customer;
};

const useCustomerDocumentsStore = () => {
  const store = useContext(StoreContext);
  return store.documents;
};

const usePartnerStore = () => {
  const store = useContext(StoreContext);
  return store.partner;
};

const useContractStore = () => {
  const store = useContext(StoreContext);
  return store.contracts;
};

const useContractStoreActions = (): IContractStoreActions => {
  const store = useContext(StoreContext);
  return {
    getContract: store.getContract,
    getContractDocuments: store.getContractDocuments,
  };
};

export {
  useUIStore,
  useCustomerStore,
  useCustomerDocumentsStore,
  usePartnerStore,
  useContractStore,
  useUIStoreActions,
  useContractStoreActions,
};
