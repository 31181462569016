import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { MessageOutlined } from "@material-ui/icons";
import { useState } from "react";
import { useUIStoreActions } from "../../hooks/useStore";
import { IContract } from "../../model/IContract";
import { IFile } from "../../model/IFile";
import { messageToPartner } from "../../util/CustomerService";

import FileUploaderControl from "../UI/FileUploaderControl";

import MessageBox from "../UI/MessageBox";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    top: "auto",
    right: theme.spacing(1),
    bottom: "64px",
  },
  select: {
    marginBottom: theme.spacing(2),
  },
}));

interface IContactPartnerDialogProps {
  contract?: IContract;
}

const ContactPartnerDialog = (props: IContactPartnerDialogProps) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<IFile[]>([]);
  const [subject, setSubject] = useState("-");
  const [messageBoxProps, setMessageBoxProps] = useState({
    isOpen: false,
    title: "",
    message: "",
  });

  const [isDlgOpen, setIsDlgOpen] = useState(false);

  const uiactions = useUIStoreActions();
  const classes = useStyles();

  const submitForm = async () => {
    const res = await messageToPartner(message, files, subject, props.contract);
    if (res) {
      setMessageBoxProps({
        title: "Sehr gut",
        message: "Ihre Nachricht wurde weitergeleitet...",
        isOpen: true,
      });
      setFiles([]);
      setMessage("");
      setSubject("-");
      setIsDlgOpen(false);
    } else {
      setMessageBoxProps({
        title: "Fehler",
        message:
          "Ihre Nachricht konnte nicht zugestellt werden, bitte versuchen Sie es später erneut!",
        isOpen: true,
      });
    }
  };

  return (
    <>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => setIsDlgOpen(true)}
      >
        <MessageOutlined />
      </Fab>
      <Dialog
        onClose={() => setIsDlgOpen(false)}
        open={isDlgOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Wie können wir helfen?</DialogTitle>
        <DialogContent>
          <>
            <Select
              fullWidth
              placeholder="Betreff"
              className={classes.select}
              value={subject}
              onChange={(e, v) => {
                setSubject(e.target.value as string);
              }}
            >
              <MenuItem value="-" disabled>
                Betreff
              </MenuItem>
              <MenuItem value="Neues Dokument">Neues Dokument</MenuItem>
              <MenuItem value="Stammdaten falsch ">
                Stammdaten falsch / nicht mehr aktuell
              </MenuItem>
              <MenuItem value="Schaden melden">Schaden melden</MenuItem>
            </Select>
            <TextField
              variant="outlined"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              multiline={true}
              minRows={6}
              label="Ihre Nachricht"
              fullWidth={true}
            ></TextField>
            <FileUploaderControl
              files={files}
              onAdd={(f: IFile) => {
                setFiles([...files, f]);
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => submitForm()}>Ok</Button>
          <Button onClick={() => setIsDlgOpen(false)}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
      <MessageBox
        isOpen={messageBoxProps.isOpen}
        title={messageBoxProps.title}
        message={messageBoxProps.message}
        onClose={() => {
          setMessageBoxProps({ title: "", message: "", isOpen: false });
        }}
      />
    </>
  );
};

export default ContactPartnerDialog;
