import axios from "../conf/axios.default";

import { IPartner } from "../model/IPartner";
import { logError } from "./log";

export const getPartner = async (): Promise<IPartner | null> => {
  try {
    const res = await axios.get("/partner");
    return res.data;
  } catch (err) {
    logError(err);
    return null;
  }
};
