import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  makeStyles,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import {
  AttachFile,
  InsertDriveFileOutlined,
  PersonOutline,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: "0",
  },
  navRoot: {
    //backgroundColor: theme.palette.primary.main,
  },
  actionRoot: {
    //color: "white !important",
  },
}));

interface IBottomNavProps {}

const BottomNav = (props: IBottomNavProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const paths = ["/contracts", "/my-profile", "/partner"];
  const [value, setValue] = React.useState("contracts");

  const handleChange = (e: any, v: any) => {
    setValue(v);
    navigate(v);
  };

  useEffect(() => {
    let v = location.pathname || "/contracts";
    paths.forEach((path) => {
      if (v.toLowerCase().includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname]);
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
        className={classes.navRoot}
      >
        <BottomNavigationAction
          className={classes.actionRoot}
          label="Meine Verträge"
          value="/contracts"
          icon={<InsertDriveFileOutlined />}
        />

        <BottomNavigationAction
          className={classes.actionRoot}
          label="Mein Profil"
          value="/my-profile"
          icon={<AttachFile />}
        />
        <BottomNavigationAction
          className={classes.actionRoot}
          label="Mein Makler"
          value="/partner"
          icon={<PersonOutline />}
        />
      </BottomNavigation>
    </AppBar>
  );
};

export default BottomNav;
