import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

import ContractListEntry from "./ContractListEntry";
import FadeInComponent from "../../components/UI/FadeInComponent";

import { ftSearchArray } from "../../util/Utilities";
import { IContract } from "../../model/IContract";
import { ICustomer } from "../../model/ICustomer";
import ContractState from "../../consts/ContractState";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flex: 1,
  },
  nothingFound: {
    marginLeft: theme.spacing(2),
  },
  new: {
    margin: theme.spacing(1),
  },
}));

const filterContracts = (
  filterValues: any,
  doShowOnlyActive: boolean,
  filterCustomerId: number | undefined
) => {
  return (contract: IContract) => {
    let match1 = !filterValues || !filterValues[0];
    let match2 = true;
    let match3 = true;

    if (doShowOnlyActive) {
      match2 =
        ContractState[contract.state] && ContractState[contract.state].isActive;
    }
    if (filterCustomerId) {
      match3 = contract.customerId === filterCustomerId;
    }
    if (match1 && match2 && match3) return true;

    match1 = filterValues.includes(contract.category.name);
    return match1 && match2;
  };
};

interface IContractOverviewListProps {
  contracts: IContract[];
  customer: ICustomer;
  settings: any;
  contractFilter: any;
  searchValue: string;
}

const ContractOverviewList = (props: IContractOverviewListProps) => {
  const classes = useStyles();

  const { contractFilter, settings, customer, contracts, searchValue } = props;

  const displayContracts = ftSearchArray(contracts, searchValue).filter(
    filterContracts(
      settings.filter,
      settings.doShowOnlyActive,
      !settings.doIncludeFamily ? customer.id : undefined
    )
  );

  displayContracts.sort((a: IContract, b: IContract) => {
    let a1, b1;

    if (settings.sort === "Gesellschaft") {
      a1 = a.contractor;
      b1 = b.contractor;

      if (settings.sortMethod === "asc") return a1.localeCompare(b1);
      else return b1.localeCompare(a1);
    } else if (settings.sort === "Enddatum") {
      a1 = new moment(a.endDate);
      b1 = new moment(b.endDate);

      if (settings.sortMethod === "asc") return a1.valueOf() - b1.valueOf();
      else return b1.valueOf() - a1.valueOf();
    } else if (settings.sort === "Kosten") {
      a1 = a.fee;
      b1 = b.fee;

      if (settings.sortMethod === "asc") return a1 - b1;
      else return b1 - a1;
    } else {
      a1 = a.category;
      b1 = b.category;

      if (settings.sortMethod === "asc") return a1.name.localeCompare(b1.name);
      else return b1.name.localeCompare(a1.name);
    }
    //TODO
  });

  if (!displayContracts || displayContracts.length === 0) {
    return (
      <>
        <span className={classes.nothingFound}>Keine Verträge gefunden</span>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={1} className={classes.root}>
        {displayContracts.map((contract: IContract, i: number) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={contract.id}>
            <ContractListEntry
              contract={contract}
              index={i}
              isFamily={contract.customerId !== customer.id}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ContractOverviewList;
