import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem",
      },
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#373737",
    },
    secondary: {
      main: "#ef7143",
      contrastText: "#fff",
    },
  },
});

export default theme;
