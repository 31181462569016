import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Button,
  Hidden,
  Container,
  CssBaseline,
  Link,
} from "@material-ui/core";

import HeaderAnonymous from "../layout/HeaderAnonymous";

import { storeToken, login } from "../../util/Auth";
import { useNavigate } from "react-router-dom";
import MessageBox from "../UI/MessageBox";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(15),
  },
  content: {
    height: 275,
    overflow: "hidden",
  },
  wallpaper: {
    backgroundImage:
      'linear-gradient(rgba(16, 154, 215, .5), rgba(16, 154, 215, .5)), url("./images/login_background.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  btnSubmit: {
    width: "30%",
  },
  txtPW: {
    marginTop: theme.spacing(1),
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [pw, setPw] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onLogin = async () => {
    setIsLoading(true);
    const result = await login(user, pw);

    if (result && result.token) {
      storeToken(result.token);
      navigate("/contracts");
    } else {
      setDisplayError(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <CssBaseline />
      <HeaderAnonymous />
      <Container fixed className={classes.container}>
        <Grid container spacing={2} className={classes.content}>
          <Hidden smDown>
            <Grid item xs={6} className={`${classes.wallpaper}`}></Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <h4>Anmeldung am Portal</h4>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="E-Mail Adresse"
                  fullWidth
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Passwort"
                  fullWidth
                  type="password"
                  value={pw}
                  onChange={(e) => setPw(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => onLogin()}
                  type="submit"
                  className={classes.btnSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                >
                  Anmelden
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link
                  component="button"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Password vergessen
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <MessageBox
        isOpen={displayError}
        title="Es ist ein Fehler aufgetreten"
        onClose={() => setDisplayError(false)}
        message="Sie haben einen ungültigen Benutzernamen oder ein ungültiges Passwort eingegeben!"
      ></MessageBox>
    </>
  );
}
