import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { isAuthenticated } from "../../util/Auth";
interface IPrivateRouteProps {
  children: any;
}

const PrivateRoute = (props: IPrivateRouteProps) => {
  const isAuth = isAuthenticated();

  return isAuth ? <Layout>{props.children}</Layout> : <Navigate to="/login" />;
};

export default PrivateRoute;
