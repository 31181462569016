import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: "50vh",
    overflow: "auto",
    minWidth: "200px",
  },
  button: {
    margin: theme.spacing(1),
    xxwidth: "95%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

interface IListSorter {
  onOk: Function;
  items: string[];
  selected: string;
  isAsc: boolean;
}
const ListSorter = (props: IListSorter) => {
  const classes = useStyles();

  const handleToggle = (value: string) => () => {
    if (value === props.selected) props.onOk(value, !props.isAsc);
    else props.onOk(value, props.isAsc);
  };

  return (
    <>
      <List component="nav" className={classes.list}>
        {props.items.map((item, i) => (
          <ListItem
            dense
            button
            onClick={handleToggle(item)}
            key={i}
            selected={props.selected === item}
          >
            <ListItemText primary={item}>aaa</ListItemText>
            {props.selected === item ? (
              <ListItemSecondaryAction>
                {props.isAsc ? <ArrowDownward /> : <ArrowUpward />}
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ListSorter;
