import ValueWithLabel from "../../components/UI/ValueWithLabel";

import { getContractFee } from "../../util/Utilities";
import { IContract } from "../../model/IContract";

interface IContractDetailsProps {
  contract: IContract;
}
const ContractDetails = (props: IContractDetailsProps) => {
  const { contract } = props;

  return (
    <>
      <ValueWithLabel
        label="Versicherung / Firma"
        value={contract.contractor}
      />
      <ValueWithLabel label="Kategorie" value={contract.category?.name} />
      <ValueWithLabel label="Vertragsnummer" value={contract.contractNumber} />
      <ValueWithLabel label="Status" value={contract.state} />
      <ValueWithLabel label="Risiko" value={contract.risk} />
      <ValueWithLabel label="Tarif" value={contract.tarrif} />
      <ValueWithLabel
        label="Laufzeit"
        value={
          contract.startDate +
          (contract.endDate ? " - " + contract.endDate : "")
        }
      />
      <ValueWithLabel
        label="Beitrag (lt. ZW)"
        value={getContractFee(contract, true)}
      />
      <ValueWithLabel label="Zahlungsweise" value={contract.feePaymentType} />
      <ValueWithLabel label="Zahlungsart" value={contract.paymentMethod} />
      <ValueWithLabel label="Name der Bank" value={contract.bankName} />
      <ValueWithLabel label="IBAN" value={contract.IBAN} />
      <ValueWithLabel label="BIC" value={contract.BIC} />
    </>
  );
};

export default ContractDetails;
