import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  makeStyles,
} from "@material-ui/core";

import React, { useRef, useEffect } from "react";

import { useUIStore, useUIStoreActions } from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid " + theme.palette.primary.main,
  },
  containerPopper: { zIndex: 9999 },
  btnActive: {
    padding: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
    border: "2px solid " + theme.palette.secondary.main,
    //color: theme.palette.secondary.contrastText
    color: theme.palette.secondary.main,
  },
}));

interface IIconButtonWithPopperProps {
  id: string;
  icon: any;
  isActive?: boolean;
  color?: any;
  children?: any;
  size?: "small" | "medium";
  className?: string;
}
const IconButtonWithPopper = (props: IIconButtonWithPopperProps) => {
  const classes = useStyles();
  const btnRef = useRef(null);
  const ui = useUIStore();
  const uiActions = useUIStoreActions();

  const { id, color, icon, isActive } = props;

  const isContextMenuOpen = ui.popperState[id] || false;

  useEffect(() => {
    return () => {
      uiActions.setPopperState(id, false);
    };
  }, []);

  const handleToggleContextMenu = () => {
    uiActions.setPopperState(id, !isContextMenuOpen);
  };

  const handleContextMenuClickAway = () => {
    if (isContextMenuOpen) {
      uiActions.setPopperState(id, false);
    }
  };

  return (
    <div>
      <IconButton
        color={color || "inherit"}
        onClick={handleToggleContextMenu}
        ref={btnRef}
        size={props.size || undefined}
        className={props.className || undefined}
        classes={{
          root: isActive ? classes.btnActive : "",
        }}
      >
        {icon}
      </IconButton>
      <Popper
        className={classes.containerPopper}
        open={isContextMenuOpen}
        anchorEl={btnRef.current}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleContextMenuClickAway}>
          <Paper className={classes.container}>{props.children}</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default IconButtonWithPopper;
