export interface IUIState {
  isLoading: boolean;
  popperState: any;
  searchValue: string;
  isSearchDisplayed: boolean;
  overviewSettings: any;
  contractFilter: any;
}

const getDefaultUIState = (): IUIState => {
  return {
    isLoading: false,
    popperState: {},
    searchValue: "",
    isSearchDisplayed: true,
    overviewSettings: {
      sort: "Typ",
      sortMethod: "asc",
      filter: [],
      doIncludeFamily: true,
      doShowOnlyActive: true,
    },
    contractFilter: {},
  };
};

export { getDefaultUIState };
