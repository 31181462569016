import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

interface IDialogProps {
  isOpen: boolean;
  title: string;
  message: string;
  onClose: Function;
}

const MessageBox = (props: IDialogProps) => {
  return (
    <Dialog open={props.isOpen} onClose={() => props.onClose()}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default MessageBox;
