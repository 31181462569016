import axios from "../conf/axios.auth";
import { logError } from "./log";

export const isAuthenticated = () => {
  const token = window.sessionStorage.getItem("CustomerToken");
  return !!token;
};

export const getToken = () => {
  return window.sessionStorage.getItem("CustomerToken");
};

export const storeToken = (token) => {
  //todo, potentially reaplce bearer etc
  window.sessionStorage.setItem("CustomerToken", token);
};

export const login = async (userName, userPassword) => {
  try {
    const data = {
      userName,
      userPassword,
    };

    let res = await axios.post("/auth/login", data);
    return res.data;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const forgotPassword = async (userName) => {
  try {
    const data = {
      userName,
    };

    let res = await axios.post("/auth/resetPW", data);
    return true;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const setPassword = async (mode, token, password, passwordRepeat) => {
  try {
    const data = {
      mode,
      token,
      password,
      passwordRepeat,
    };

    let res = await axios.post("/auth/setPw", data);
    return res.data;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const validateToken = async (token) => {
  try {
    const data = {
      token,
    };

    let res = await axios.post("/auth/validateToken", data);
    return res.data;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const validateMVPToken = async (mvpToken) => {
  try {
    const data = {
      mvpToken,
    };

    let res = await axios.post("/auth/validateMVPToken", data);
    return res.data;
  } catch (err) {
    logError(err);
    return false;
  }
};

export const logout = () => {
  window.sessionStorage.removeItem("CustomerToken");
};
