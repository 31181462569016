import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Tabs,
  Box,
  Tab,
  Container,
  Card,
  CardHeader,
  CardContent,
  Fab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Attachment, Message, MessageOutlined } from "@material-ui/icons";

import FABButtonWithPopper from "../UI/FABButtonWithPopper";
import Loading from "../UI/Loading";
import { useNavigate, useParams } from "react-router-dom";

import ContractDocuments from "../ContractDetails/ContractDocuments";
import {
  useContractStore,
  useContractStoreActions,
  useCustomerStore,
  useUIStore,
  useUIStoreActions,
} from "../../hooks/useStore";
import { IContract } from "../../model/IContract";
import ContractDetails from "../ContractDetails/ContractDetails";
import { IDocument } from "../../model/IDocument";
import ContactPartnerDialog from "../dialogs/ContactPartnerDialog";

const useStyles = makeStyles((theme) => ({
  cardContent: {},
  cardHeader: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontStyle: "bold",
  },

  container: { paddingTop: theme.spacing(3) },
}));

interface IContractProps {}
const Contract = (props: IContractProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [contract, setContract] = useState<IContract | null>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const ui = useUIStore();
  const customer = useCustomerStore();
  const uiactions = useUIStoreActions();

  const contractActions = useContractStoreActions();

  const params = useParams();
  const contractId = parseInt(params.contractId + "");

  if (!contractId) {
    //TODO
  }

  useEffect(() => {
    uiactions.setIsSearchDisplayed(false);
  }, []);

  useEffect(() => {
    const run = async () => {
      const c = await contractActions.getContract(contractId);
      const docs = await contractActions.getContractDocuments(contractId);
      setContract(c);
      setDocuments(docs);
    };

    run();
  }, [contractId]);

  if (ui.isLoading || !contract || !customer) return <Loading />;

  return (
    <Container className={classes.container} maxWidth="md">
      <Grid container={true} spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Ihr Vertrag"
              className={classes.cardHeader}
              classes={{
                title: classes.title,
              }}
            />
            <CardContent className={classes.cardContent}>
              <ContractDetails contract={contract} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Dokumente zu diesem Vertrag"
              className={classes.cardHeader}
              classes={{
                title: classes.title,
              }}
            />
            <CardContent className={classes.cardContent}>
              <ContractDocuments
                customer={customer}
                documents={documents}
                contract={contract}
              ></ContractDocuments>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ContactPartnerDialog contract={contract} />
    </Container>
    /*
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <span className={classes.h2}>{contract.category.name}</span>
            <span className={classes.h4}> ({contract.contractor})</span>
          </div>

          <Tabs
            value={tabsValue}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="primary"
          >
            <Tab label="Details" className={classes.tab} />
            <Tab label="Dokumente" className={classes.tab} />
          </Tabs>
          <TabPanel value={tabsValue} index={0} className={classes.content}>
            
          </TabPanel>
          <TabPanel value={tabsValue} index={1}>
           
          </TabPanel>
        </div>

        //FAB BUTTON HERE
      </Grid>
    </Grid>
    */
  );
};

export default Contract;
