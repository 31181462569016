import axios from "../conf/axios.default";

import { IDocument } from "../model/IDocument";
import { getToken } from "./Auth";
import { logError } from "./log";

export const getDocuments = async (): Promise<IDocument[]> => {
  try {
    const res = await axios.get("/me/documents");
    return res.data;
  } catch (err) {
    logError(err);
    return [];
  }
};

export const openDocument = (document: IDocument) => {
  const url = process.env.REACT_APP_API_PATH;
  const token = getToken();

  if (document.contractId) {
    window.open(
      `${url}/contracts/${document.contractId}/documents/${document.id}?token=${token}`,
      "_blank"
    );
  } else {
    window.open(`${url}/me/documents/${document.id}?token=${token}`, "_blank");
  }
};
