import FadeInComponent from "../UI/FadeInComponent";

import { ftSearchArray } from "../../util/Utilities";
import { useUIStore } from "../../hooks/useStore";
import { IDocument } from "../../model/IDocument";
import { IContract } from "../../model/IContract";
import { ICustomer } from "../../model/ICustomer";
import DocumentOverview from "../UI/DocumentOverview";

var moment = require("moment");

interface IContractDocumentsProps {
  documents: IDocument[];
  contract: IContract;
  customer: ICustomer;
}
const ContractDocuments = (props: IContractDocumentsProps) => {
  const ui = useUIStore();

  const { documents } = props;

  const displayDocuments: IDocument[] = ftSearchArray(
    documents,
    ui.searchValue
  );

  displayDocuments &&
    displayDocuments.sort((a, b) => {
      const a1 = new moment(a.createdDate);
      const b1 = new moment(b.createdDate);

      return b1.valueOf() - a1.valueOf();
    });

  return (
    <>
      <FadeInComponent>
        <DocumentOverview documents={displayDocuments} />
      </FadeInComponent>
    </>
  );
};

export default ContractDocuments;
