import React, { useEffect } from "react";

import ContractOverviewList from "../ContractOverview/ContractOverviewList";
import ContractsToolbar from "../ContractOverview/ContractsToolbar";
import {
  useContractStore,
  useCustomerStore,
  useUIStore,
  useUIStoreActions,
} from "../../hooks/useStore";
import Loading from "../UI/Loading";

interface IContractOverviewProps {}

const ContractOverview = (props: IContractOverviewProps) => {
  const ui = useUIStore();
  const uiactions = useUIStoreActions();
  const customer = useCustomerStore();
  const contracts = useContractStore();

  useEffect(() => {
    uiactions.setIsSearchDisplayed(true);
  }, []);

  if (ui.isLoading || !customer) return <Loading />;

  return (
    <>
      <ContractsToolbar contracts={contracts} customer={customer} />
      <ContractOverviewList
        contracts={contracts}
        customer={customer}
        settings={ui.overviewSettings}
        contractFilter={ui.contractFilter}
        searchValue={ui.searchValue}
      />
    </>
  );
};

export default ContractOverview;
