import {
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Attachment } from "@material-ui/icons";
import { IDocument } from "../../model/IDocument";
import { openDocument } from "../../util/DocumentsService";
import { asDisplayDate } from "../../util/Utilities";

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: "pointer",
  },
}));

interface IDocumentOverviewProps {
  documents: IDocument[];
}

const DocumentOverview = (props: IDocumentOverviewProps) => {
  const classes = useStyles();

  if (!props.documents || props.documents.length === 0) {
    return <>Keine Dokumente gefunden bzw. freigegeben</>;
  }

  return (
    <List>
      {props.documents.map((document) => (
        <ListItem
          key={document.id}
          className={classes.item}
          onClick={() => openDocument(document)}
        >
          <ListItemAvatar>
            <Attachment />
          </ListItemAvatar>
          <ListItemText
            primary={`${document.name}`}
            secondary={`Erstellt am ${asDisplayDate(document.createdDate)}`}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DocumentOverview;
