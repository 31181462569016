import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";
import "./ShowLegalDialog.css";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    top: "auto",
    right: theme.spacing(1),
    bottom: "64px",
  },
  select: {
    marginBottom: theme.spacing(2),
  },
}));

interface IShowLegalDialogProps {
  mode?: "INFO" | "COOKIES" | "DATA" | "IMPRINT";
  onClose: Function;
}

const ShowLegalDialog = (props: IShowLegalDialogProps) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const classes = useStyles();

  useEffect(() => {
    let url = "";
    if (!props.mode) return;

    const exec = async (url: string) => {
      const ret = await axios.get(url);
      setContent(ret.data);
    };

    switch (props.mode) {
      case "IMPRINT":
        setTitle("Impressum");
        url = "./imprint.html";
        break;
      case "INFO":
        setTitle("Informationspflichten");
        url = "./info.html";
        break;
      case "DATA":
        setTitle("Datenschutz");
        url = "./data.html";
    }

    exec(url);
  }, [props.mode]);

  return (
    <Dialog
      onClose={() => props.onClose()}
      open={!!props.mode}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowLegalDialog;
