import {
  ClickAwayListener,
  Button,
  Paper,
  Popper,
  makeStyles,
  ButtonBaseProps,
} from "@material-ui/core";

import React, { useRef, useEffect } from "react";
import { useUIStore, useUIStoreActions } from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid " + theme.palette.primary.main,
  },
  btnActive: {
    padding: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
    border: "2px solid " + theme.palette.secondary.main,
    //color: theme.palette.secondary.contrastText
    color: theme.palette.secondary.main,
  },
}));

interface IButtonWithPopperProps extends ButtonBaseProps {
  id: string;
  icon: any;
  label: string;
  isActive?: boolean;
  color?: any;
}

const ButtonWithPopper = (props: IButtonWithPopperProps) => {
  const classes = useStyles();
  const btnRef = useRef(null);

  const ui = useUIStore();
  const uiActions = useUIStoreActions();

  const { id, label, icon, isActive, ...other } = props;

  const isContextMenuOpen = ui.popperState[id] || false;

  useEffect(() => {
    return () => {
      uiActions.setPopperState(id, false);
    };
  }, []);

  const handleToggleContextMenu = (e: any) => {
    uiActions.setPopperState(id, !isContextMenuOpen);
  };

  const handleContextMenuClickAway = () => {
    if (isContextMenuOpen) {
      uiActions.setPopperState(id, false);
    }
  };

  return (
    <div>
      <Button
        {...other}
        onClick={handleToggleContextMenu}
        ref={btnRef}
        classes={{
          root: isActive ? classes.btnActive : "",
        }}
        startIcon={icon}
      >
        {label}
      </Button>
      <Popper
        style={{ zIndex: 99999 }}
        open={isContextMenuOpen}
        anchorEl={btnRef.current}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleContextMenuClickAway}>
          <Paper className={classes.container}>{props.children}</Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default ButtonWithPopper;
