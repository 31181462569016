import React, { useState } from "react";
import {
  AppBar,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import IconButtonWithPopper from "../UI/IconButtonWithPopper";
import { useNavigate } from "react-router-dom";
import ShowLegalDialog from "../dialogs/ShowLegalDialog";
import { useUIStoreActions } from "../../hooks/useStore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    margin: theme.spacing(1.5),
    marginLeft: 0,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      height: "70",
    },
  },
  logoText: {
    height: 45,
    marginRight: theme.spacing(1.5),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

interface IHeaderAnonymousProps {}

const HeaderAnonymous = (props: IHeaderAnonymousProps) => {
  const classes = useStyles();
  const uiActions = useUIStoreActions();

  const [legalMode, setLegalMode] = useState<
    undefined | "INFO" | "COOKIES" | "DATA" | "IMPRINT"
  >();

  const menuIdLegal = "Header-LegalBtnMenu";
  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <img src="./Logo.png" alt="" className={classes.logo} />

          <img src="./Text.png" alt="" className={classes.logoText} />

          <div className={classes.grow}></div>
          <div>
            <IconButtonWithPopper
              id={menuIdLegal}
              icon={<MoreVert />}
              size="small"
            >
              <List component="nav">
                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("DATA");
                  }}
                >
                  <ListItemText primary="Datenschutz" />
                </ListItem>

                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("INFO");
                  }}
                >
                  <ListItemText primary="Informationspflichten" />
                </ListItem>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    uiActions.setPopperState(menuIdLegal, false);
                    setLegalMode("IMPRINT");
                  }}
                >
                  <ListItemText primary="Impressum" />
                </ListItem>
              </List>
            </IconButtonWithPopper>
          </div>
        </Toolbar>
      </AppBar>

      <ShowLegalDialog
        mode={legalMode}
        onClose={() => {
          setLegalMode(undefined);
        }}
      ></ShowLegalDialog>
    </div>
  );
};

export default HeaderAnonymous;
