import React, { useRef } from "react";

interface IFadeInComponentProps {
  children: React.ReactNode;
}
const FadeInComponent = (props: IFadeInComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      //className={"animated faster" + (!props.isFilterable ? " fadeInUp" : "")}
    >
      {props.children}
    </div>
  );
};

export default FadeInComponent;
