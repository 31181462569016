import axios from "../conf/axios.default";
import { IContract } from "../model/IContract";
import { IDocument } from "../model/IDocument";
import { logError } from "./log";

export const getContracts = async (): Promise<IContract[]> => {
  try {
    const res = await axios.get("/contracts");
    return res.data;
  } catch (err) {
    logError(err);
    return [];
  }
};

export const getContract = async (id: number): Promise<IContract | null> => {
  try {
    const res = await axios.get("/contracts/" + id);
    return res.data;
  } catch (err) {
    logError(err);
    return null;
  }
};

export const getContractDocuments = async (
  id: number
): Promise<IDocument[]> => {
  try {
    const res = await axios.get("/contracts/" + id + "/documents");
    return res.data;
  } catch (err) {
    logError(err);
    return [];
  }
};
