import React from "react";

import { CssBaseline, makeStyles } from "@material-ui/core";
import Header from "./Header";
import BottomNav from "./BottomNav";
//import ErrorBoundary from "../../../../shared/UI/ErrorBoundary";
import ErrorBoundary from "../../shared/components/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 128,
    paddingTop: 82,
  },
}));

interface ILayoutProps {
  children: any;
}

export default function Layout(props: ILayoutProps) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Header />
      <div className={classes.wrapper}>{props.children}</div>
      <BottomNav />
    </>
  );
}
