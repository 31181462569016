import moment from "moment";
import { getToken } from "./Auth";

export const updateStateObject = (state, newValue) => {
  return {
    ...state,
    ...newValue,
  };
};

export const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const ftSearchArray = (arr, searchKey) => {
  if (!arr || !arr[0] || !searchKey) return arr;

  return arr.filter(function (obj) {
    return Object.keys(obj).some(function (key) {
      const isTrue = JSON.stringify(obj[key])
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase());

      if (isTrue) {
        console.log(obj);
        console.log(key);
        console.log(obj[key]);
      }

      return isTrue;
    });
  });
};

export const asDisplayDate = (date) => {
  if (!date) return null;
  return moment(date).format("DD.MM.YYYY");
};

export const getOpenDocumentHref = (customerId, documentId) => {
  const base = process.env.REACT_APP_API_PATH;
  const token = getToken();
  return (
    base +
    "/customers/" +
    customerId +
    "/documents/" +
    documentId +
    "?token=" +
    token
  );
};

export const getOpenContractDocumentHref = (
  customerId,
  contractId,
  documentId
) => {
  const base = process.env.REACT_APP_API_PATH;
  const token = getToken();
  return `${base}/customers/${customerId}/contracts/${contractId}/documents/${documentId}?token=${token}`;
};

export const getContractFee = (c, includeAdd = false) => {
  let fee = c.fee;

  if (c.feeTaxRate) fee = fee * ((100 + c.feeTaxRate) / 100);

  if (c.feePaymentType) fee = fee / c.feePaymentType;

  if (c.feeAdd) fee = fee * ((100 + c.feeAdd) / 100);

  let ret = fee.toFixed(2) + " €";

  if (includeAdd & c.feeAdd) ret = ret + " (+" + c.feeAdd + "%)";
  return ret;
};
