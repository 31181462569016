import React, { useState } from "react";
import {
  makeStyles,
  ListItem,
  List,
  ListItemIcon,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { Attachment, PhotoCamera } from "@material-ui/icons";
import FileUpload from "./FileUpload";
import ButtonWithPopper from "./ButtonWithPopper";
import { useUIStore, useUIStoreActions } from "../../hooks/useStore";
import { IFile } from "../../model/IFile";

const useStyles = makeStyles((theme) => ({
  input: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  btnUpload: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
  errorText: {
    fontSize: 10,
  },
}));

interface IFileUploaderControlProps {
  files: IFile[];
  label?: string;
  onAdd: (f: IFile) => void;
}

const FileUploaderControl = (props: IFileUploaderControlProps) => {
  const bid = "file-upload-cc";
  const classes = useStyles();
  const { label } = props;
  const uiActions = useUIStoreActions();

  const handleFileUpload = (f: IFile) => {
    uiActions.setPopperState(bid, false);

    if (props.onAdd) props.onAdd(f);
  };

  return (
    <>
      {props.label ? <FormLabel component="legend">{label}</FormLabel> : null}
      <ButtonWithPopper
        id={bid}
        label="Datei hochladen"
        icon={<Attachment />}
        className={classes.btnUpload}
      >
        <List component="nav">
          <ListItem button>
            <ListItemIcon>
              <PhotoCamera />
            </ListItemIcon>
            <FileUpload
              id="uploadCamera"
              useCamera={true}
              onChange={handleFileUpload}
            ></FileUpload>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Attachment />
            </ListItemIcon>
            <FileUpload
              onChange={handleFileUpload}
              id="uploadSystem"
              useCamera={false}
            ></FileUpload>
          </ListItem>
        </List>
      </ButtonWithPopper>

      <List component="nav">
        {props.files &&
          props.files.length > 0 &&
          props.files.map((f: IFile) => (
            <ListItem key={f.name}>{f.name}</ListItem>
          ))}
      </List>
    </>
  );
};

export default FileUploaderControl;
