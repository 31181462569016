import React, { Reducer, useEffect, useReducer, useState } from "react";
import { IContract } from "../model/IContract";
import { ICustomer } from "../model/ICustomer";
import { IDocument } from "../model/IDocument";
import { IPartner } from "../model/IPartner";

import { IStore } from "../model/IStore";
import { getDefaultUIState, IUIState } from "../model/IUIState";
import { isAuthenticated } from "../util/Auth";
import {
  getContracts,
  getContract,
  getContractDocuments,
} from "../util/ContractsService";
import { getCustomer } from "../util/CustomerService";
import { getDocuments } from "../util/DocumentsService";
import { getPartner } from "../util/PartnerService";

const StoreContext = React.createContext<IStore>({} as IStore);

interface IStoreProviderProps {
  children: any;
}

interface IContractCache {
  [key: number]: {
    contract: IContract;
    documents: IDocument[];
  };
}

/*
interface IContractCategegoryCache {
  [key: string]: IContractCategory;
}
*/
const StoreProvider = (props: IStoreProviderProps) => {
  //const [ui, setUI] = useState<IUIState>(getDefaultUIState());
  const [ui, setUI] = useReducer<Reducer<IUIState, Partial<IUIState>>>(
    (state: IUIState, newState: Partial<IUIState>) => ({
      ...state,
      ...newState,
    }),
    getDefaultUIState()
  );

  const [contractCache, setContractCache] = useReducer<
    Reducer<IContractCache, IContractCache>
  >(
    (state: IContractCache, newState: IContractCache) => ({
      ...state,
      ...newState,
    }),
    {}
  );

  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [partner, setPartner] = useState<IPartner | null>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const isAuth = isAuthenticated();

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const c = await getContracts();
      setContracts(c);

      const p = await getPartner();
      setPartner(p);

      const d = await getDocuments();
      setDocuments(d);

      const cu = await getCustomer();
      setCustomer(cu);

      setIsLoading(false);
    };

    if (isAuth) run();
  }, [isAuth]);

  const getContractFromStore = async (
    id: number
  ): Promise<IContract | null> => {
    const cacheEntry = contractCache[id] || {};
    let c: IContract | null | undefined =
      cacheEntry.contract || contracts.find((ct) => ct.id === id);

    if (!c) {
      //fetch it
      c = await getContract(id);
      if (c) {
        setContractCache({
          [c.id]: { contract: c, documents: cacheEntry.documents || [] },
        });
      }
    }
    return c;
  };

  const getContractDocumentsFromStore = async (
    id: number
  ): Promise<IDocument[]> => {
    const cacheEntry = contractCache[id] || {};
    let documents: IDocument[] = cacheEntry.documents || [];
    if (!documents || documents.length === 0) {
      //fetch it
      documents = await getContractDocuments(id);
      if (documents && documents.length > 0) {
        setContractCache({
          [id]: { contract: cacheEntry.contract, documents },
        });
      }
    }
    return documents;
  };

  const setPopperState = (id: string, value: boolean) => {
    const popperState = { ...ui.popperState };

    popperState[id] = value;

    setUI({ popperState });
  };

  const setSearchValue = (value: string) => {
    setUI({ searchValue: value });
  };

  const setIsSearchDisplayed = (value: boolean) => {
    setUI({ isSearchDisplayed: value });
  };

  const setIsLoading = (value: boolean) => {
    setUI({ isLoading: value });
  };

  const setOverviewSettings = (
    sort: string,
    sortMethod: "asc" | "desc",
    filter: string[],
    doIncludeFamily: boolean,
    doShowOnlyActive: boolean
  ) => {
    setUI({
      overviewSettings: {
        sort,
        sortMethod,
        filter,
        doIncludeFamily,
        doShowOnlyActive,
      },
    });
  };

  const setContractFilter = (value: any) => {
    setUI({ contractFilter: value });
  };

  const store: IStore = {
    customer: customer,
    partner: partner,
    contracts: contracts,
    documents: documents,
    ui: {
      isLoading: ui.isLoading,
      popperState: ui.popperState,
      searchValue: ui.searchValue,
      isSearchDisplayed: ui.isSearchDisplayed,
      overviewSettings: ui.overviewSettings,
      contractFilter: ui.contractFilter,
    },
    setIsLoading,
    setPopperState,
    setSearchValue,
    setIsSearchDisplayed,

    setOverviewSettings,
    setContractFilter,
    getContract: getContractFromStore,
    getContractDocuments: getContractDocumentsFromStore,
  };

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext };
export default StoreProvider;
