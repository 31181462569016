import React from "react";
import { Route, Routes } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import "./App.css";
import ChangePassword from "./components/pages/ChangePassword";

import Contract from "./components/pages/Contract";
import ContractOverview from "./components/pages/ContractOverview";
import Login from "./components/pages/Login";
import MyProfile from "./components/pages/MyProfile";
import MyPartner from "./components/pages/MyPartner";
import PrivateRoute from "./components/util/PrivateRoute";
import StoreContext from "./contexts/StoreContext";
import MyDocuments from "./components/pages/MyDocuments";
import ForgotPasswordPage from "./components/pages/ForgotPassword";
import SetPasswordPage from "./components/pages/SetPassword";

const App = () => {
  return (
    <StoreContext>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <ContractOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/contracts/:contractId"
            element={
              <PrivateRoute>
                <Contract />
              </PrivateRoute>
            }
          />
          <Route
            path="/contracts"
            element={
              <PrivateRoute>
                <ContractOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <PrivateRoute>
                <MyDocuments />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-profile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/partner"
            element={
              <PrivateRoute>
                <MyPartner />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/set-password" element={<SetPasswordPage />} />
        </Routes>
      </Router>
    </StoreContext>
  );
};

export default App;
