import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  makeStyles,
} from "@material-ui/core";

import { Done, CancelOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "50vh",
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(1),
    xxwidth: "95%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));
interface IListFilterProps {
  selected: string[];
  items: string[];
  onOk: Function;
}
const ListFilter = (props: IListFilterProps) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState([...props.selected]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <List component="nav" className={classes.list}>
        {props.items.map((item, i) => (
          <ListItem dense button onClick={handleToggle(item)} key={i}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(item) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.button}
        onClick={() => props.onOk(checked)}
      >
        <Done className={classes.leftIcon} />
        Übernehmen
      </Button>

      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.button}
        onClick={() => props.onOk([])}
      >
        <CancelOutlined className={classes.leftIcon} />
        Zurücksetzen
      </Button>
    </>
  );
};

export default ListFilter;
