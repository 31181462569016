import axios from "../conf/axios.default";
import { IContract } from "../model/IContract";
import { ICustomer } from "../model/ICustomer";
import { logError } from "./log";

export const getCustomer = async (): Promise<ICustomer | null> => {
  try {
    const res = await axios.get("/me");
    return res.data;
  } catch (err) {
    logError(err);
    return null;
  }
};

export const messageToPartner = async (
  message: string,
  files: any[],
  subject?: string,
  contract?: IContract
): Promise<boolean> => {
  try {
    const res = await axios.post("/me/contact-partner", {
      message,
      files,
      subject: subject === "-" ? undefined : subject,
      contract,
    });
    return true;
  } catch (err) {
    logError(err);
    return false;
  }
};
